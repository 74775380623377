import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  position: number;
}

export default function Participant({ participant, disableAudio, position }: ParticipantProps) {
  return (
    <ParticipantInfo participant={participant} position={position}>
      <ParticipantTracks participant={participant} disableAudio={disableAudio} />
    </ParticipantInfo>
  );
}
