import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const useStyles = makeStyles(theme => ({
  lockIcon: {},
}));

interface AccessBoxProps {
  password: (e: string) => void;
}

export default function AccessBox({ password }: AccessBoxProps) {
  const classes = useStyles();
  const [userInput, setuserInput] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="unlock" onClick={handleClickOpen} className={classes.lockIcon} color="secondary">
        <FingerprintIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TextField
            id="outlined-password-input"
            label="Access Code"
            type="password"
            variant="outlined"
            placeholder="Access Code"
            onChange={e => setuserInput(e.target.value)}
            value={userInput}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              password(userInput);
            }}
            color="primary"
            autoFocus
          >
            Validate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
