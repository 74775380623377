import React, { useContext } from 'react';
import clsx from 'clsx';
import IframeComponent from '../IframeComponent/IframeComponent';
import { TemplateContext } from '../TemplateProvider';
import { makeStyles } from '@material-ui/core/styles';
import TVStatic from '../../assets/img/static169.gif';

const useStyles = makeStyles({
  tour3d: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'T',
  },
  static:{
    backgroundImage: `url(${TVStatic})`,
    backgroundSize: '100%',
  },
  noBorder: {
    border: 'none',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  maximize: {
    top: '0px',
    left: '0px',
    width: '100vw',
    height: '100vh',
    zIndex: 99999,
  },
});

interface Tour3DProps {
  src: string;
  area: number;
  children: React.ReactNode;
}

export default function Tour3D({ src, area, children }: Tour3DProps) {
  const classes = useStyles();
  const { isFullScreen } = useContext(TemplateContext);
  const maximize = area === isFullScreen;
  const noSrc = src === '';

  return (
    <div className={clsx(
      { [classes.tour3d]: !maximize },
      { [classes.maximize]: maximize },
      { [classes.static]: noSrc })
    }>
      {children}
      <IframeComponent
        src={src}
        width={'100%'}
        height={'100%'}
        divClass={classes.fullSize}
        iframeClass={classes.noBorder}
      />
    </div>
  );
}
