import React, { useContext } from 'react';
import clsx from 'clsx';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import { TemplateContext } from '../TemplateProvider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  containerOp1: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'M',
  },
  containerOp2: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'S',
  },
  maximize: {
    zIndex: 99999,
    margin: '0px auto',
  },
});

interface MainParticipantProps {
  operator: string;
  area: number;
  children: React.ReactNode;
}

export default function MainParticipant({ operator, area, children }: MainParticipantProps) {
  const classes = useStyles();
  const participants = useParticipants();
  const mainParticipant = participants.filter(participant => participant.identity === operator)[0];
  const { isFullScreen } = useContext(TemplateContext);
  const maximize = area === isFullScreen;
  const isOperatorOne = operator === 'operator1' && !maximize;
  const isOperatorTwo = operator === 'operator2' && !maximize;

  if (!mainParticipant) {
    return null;
  }

  // const mainParticipant = useMainSpeaker(operator);
  // const [selectedParticipant] = useSelectedParticipant();
  // const screenShareParticipant = useScreenShareParticipant();

  const videoPriority = 'high';
  // mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? 'high' : null;

  return (
    /* audio is disabled for this participant component because this participant's audio
           is already being rendered in the <ParticipantStrip /> component.  */
    <div
      className={clsx(
        { [classes.maximize]: maximize },
        { [classes.containerOp1]: isOperatorOne },
        { [classes.containerOp2]: isOperatorTwo }
      )}
    >
      {children}
      <MainParticipantInfo participant={mainParticipant}>
        <ParticipantTracks participant={mainParticipant} videoPriority={videoPriority} />
      </MainParticipantInfo>
    </div>
  );
}
