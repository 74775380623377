import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { TemplateContext } from '../../TemplateProvider';

interface ZoomInBtnProps {
  area: number;
}

export default function ZoomBtn({ area }: ZoomInBtnProps) {
  // const classes = useStyles();
  const { gridArea, setGridArea } = useContext(TemplateContext);
  const { isFullScreen, setFullScreen } = useContext(TemplateContext);
  const maximize = area === gridArea;

  if (maximize) {
    // setFullScreen(99);
    return (
      <IconButton onClick={() => (isFullScreen === area ? setFullScreen(99) : setFullScreen(area))} color="secondary">
        {isFullScreen === area ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
    );
  } else {
    return (
      <IconButton onClick={() => setGridArea(area)} color="secondary">
        <VideoLabelIcon />
      </IconButton>
    );
  }
}
