import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
// import PinIcon from './PinIcon/PinIcon';
// import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';

import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';
import videomask from '../../assets/img/videomask.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      cursor: 'pointer',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      // padding: '0.4em',
      width: '100%',
      background: 'transparent',
    },
    videomask: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
    imgmask: {
      width: '100%',
      height: '100%',
    },
    hideVideo: {
      background: '303030',
    },
    identity: {
      // background: 'rgba(0, 0, 0, 0.2)',
      padding: '0.1em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRow: {
      display: 'flex',
      // justifyContent: 'space-between',
      background: 'rgba(48, 48, 48)',
      border: '2px solid rgb(6e6e6e)',
      borderRadius: '5px',
      margin: '0px auto',
      marginTop: 'auto',
    },
    itemA: {
      gridArea: 'A',
    },
    itemB: {
      gridArea: 'B',
    },
    itemC: {
      gridArea: 'C',
    },
    itemD: {
      gridArea: 'D',
    },
    itemE: {
      gridArea: 'E',
    },
    itemF: {
      gridArea: 'F',
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  position: number;
  children: React.ReactNode;
}

export default function ParticipantInfo({ participant, position, children }: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isVideoEnabled = Boolean(videoPublication);
  // const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;

  const classes = useStyles();
  const gridItems = [classes.itemA, classes.itemB, classes.itemC, classes.itemD, classes.itemE, classes.itemF];

  return (
    <div
      className={clsx(
        classes.container,
        { [classes.isVideoSwitchedOff]: isVideoSwitchedOff },
        `${gridItems[position]}`
      )}
      data-cy-participant={participant.identity}
    >
      <div className={clsx(classes.infoContainer, { [classes.hideVideo]: !isVideoEnabled })}>
        <div className={classes.infoRow}>
          <h4 className={classes.identity}>
            <ParticipantConnectionIndicator participant={participant} />
            {participant.identity}
          </h4>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
          <AudioLevelIndicator audioTrack={audioTrack} background="white" />
          {!isVideoEnabled && <VideocamOff />}
        </div>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
      <div className={classes.videomask}>
        <img src={videomask} className={classes.imgmask} alt={participant.identity} />
      </div>
    </div>
  );
}
