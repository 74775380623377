import React from 'react';

interface IframeComponentProps {
  src: string;
  height: string;
  width: string;
  divClass: string;
  iframeClass: string;
}

export default function IframeComponent({ src, height, width, divClass, iframeClass }: IframeComponentProps) {
  return (
    <div className={divClass}>
      <iframe src={src} height={height} width={width} className={iframeClass} title="3D Tour" />
    </div>
  );
}
