import React, { useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import { TemplateContext } from '../TemplateProvider';

const Overlay = styled('div')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100vw',
  height: '100vh',
  backgroundColor: `black`,
  overflow: 'hidden',
  zIndex: 5000,
});

export default function BlackOverlay() {
  const { isFullScreen } = useContext(TemplateContext);
  const hide = isFullScreen !== 99;
  if (hide) {
    return <Overlay />;
  } else {
    return null;
  }
}
