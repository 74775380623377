import React, { useState } from 'react';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Participant from '../Participant/Participant';
import MainParticipant from '../MainParticipant/MainParticipant';
import Tour3D from '../Tour3D/Tour3D';
import { TemplateProvider } from '../TemplateProvider';
import AccessBoxBtn from '../ToolBar/AccessBox/AccessBox';
import ZoomBtn from '../ToolBar/ZoomBtn/ZoomBtn';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'absolute',
    top: '12px',
    right: '13px',
    zIndex: 999,
    padding: '0px !important',
    minHeight: '0px',
  },
}));

export default function Room() {
  const classes = useStyles();
  const participants = useParticipants();
  const {
    room: { localParticipant },
  } = useVideoContext();

  const [pass, setPass] = useState('');

  const getURL = (code: string) => {
    const urls = [
      { code: '98762', url: 'https://my.matterport.com/show/?m=N2sAxsjo86o&play=1' },
      { code: '79303', url: 'https://my.matterport.com/show/?m=S8kzVoL6ckV&play=1' },
      { code: '42901', url: '' },
    ];
    const index = urls
      .map(function(e) {
        return e.code;
      })
      .indexOf(code);
    return index !== -1 ? urls[index].url : '';
  };

  return (
    <TemplateProvider options={true}>
      <Participant participant={localParticipant} position={0} />
      {participants
        .filter(participant => participant.identity !== 'operator1' && participant.identity !== 'operator2')
        .slice(0, 5)
        .map((participant, index) => (
          <Participant key={participant.sid} participant={participant} position={index + 1} />
        ))}

      <MainParticipant area={0} operator={'operator1'}>
        <Toolbar className={classes.toolbar}>
          <ZoomBtn area={0} />
        </Toolbar>
      </MainParticipant>

      <MainParticipant area={1} operator={'operator2'}>
        <Toolbar className={classes.toolbar}>
          <ZoomBtn area={1} />
        </Toolbar>
      </MainParticipant>

      <Tour3D src={getURL(pass)} area={2}>
        <Toolbar className={classes.toolbar}>
          <ZoomBtn area={2} />
          <AccessBoxBtn password={password => setPass(password)} />
        </Toolbar>
      </Tour3D>
    </TemplateProvider>
  );
}
