import React, { useState, createContext, ReactNode } from 'react';
import clsx from 'clsx';
import { styled, makeStyles } from '@material-ui/core/styles';
import BackgroundImg from '../../assets/img/background.png';
import BlackOverlay from '../BlackOvelay/BlackOvelay';

export interface ITemplateContext {
  gridArea: number;
  setGridArea: (area: number) => void;
  isFullScreen: number;
  setFullScreen: (area: number) => void;
}

export const TemplateContext = createContext<ITemplateContext>(null!);

const AspectRatio = styled('div')({
  width: '100vw',
  height: '56.25vw',
  // 100/56.25 = 1.778
  // background: 'pink',
  maxHeight: '100vh',
  maxWidth: '177.78vh',
  // 16/9 = 1.778
  margin: 'auto',
  position: 'absolute',
  top: '0',
  bottom: '0',
  // Vertical center
  left: '0',
  right: '0',
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: '100%',
  // horizontal center
  overflow: 'hidden',
});

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    top: '0',
    left: '0',
    display: 'grid',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    gridTemplateColumns: 'repeat(16, 1fr)',
    gridTemplateRows: 'repeat(16, 1fr)',
  },
  One: {
    gridTemplateAreas:
      '". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ." "A A A M M M M M M M M G G D D D" "A A A M M M M M M M M G G D D D" "A A A M M M M M M M M G G D D D" ". . . M M M M M M M M G G . . ." "B B B M M M M M M M M G G E E E" "B B B M M M M M M M M G G E E E" "B B B M M M M M M M M G G E E E" ". . . M M M M M M M M G G . . ." "C C C T T T T S S S S G G F F F" "C C C T T T T S S S S G G F F F" "C C C T T T T S S S S G G F F F" ". . . T T T T S S S S G G . . ." ". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ."',
  },
  Two: {
    gridTemplateAreas:
      '". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ." "A A A S S S S S S S S G G D D D" "A A A S S S S S S S S G G D D D" "A A A S S S S S S S S G G D D D" ". . . S S S S S S S S G G . . ." "B B B S S S S S S S S G G E E E" "B B B S S S S S S S S G G E E E" "B B B S S S S S S S S G G E E E" ". . . S S S S S S S S G G . . ." "C C C T T T T M M M M G G F F F" "C C C T T T T M M M M G G F F F" "C C C T T T T M M M M G G F F F" ". . . T T T T M M M M G G . . ." ". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ."',
  },
  Three: {
    gridTemplateAreas:
      '". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ." "A A A T T T T T T T T G G D D D" "A A A T T T T T T T T G G D D D" "A A A T T T T T T T T G G D D D" ". . . T T T T T T T T G G . . ." "B B B T T T T T T T T G G E E E" "B B B T T T T T T T T G G E E E" "B B B T T T T T T T T G G E E E" ". . . T T T T T T T T G G . . ." "C C C M M M M S S S S G G F F F" "C C C M M M M S S S S G G F F F" "C C C M M M M S S S S G G F F F" ". . . M M M M S S S S G G . . ." ". . . . . . . . . . . . . . . ." ". . . . . . . . . . . . . . . ."',
  },
}));

interface TemplateProviderProps {
  options: true;
  children: ReactNode;
}

export function TemplateProvider({ options, children }: TemplateProviderProps) {
  const classes = useStyles();
  const [gridArea, setGridArea] = useState(0);
  const [isFullScreen, setFullScreen] = useState(99);
  const gridTemplate = [classes.One, classes.Two, classes.Three];

  return (
    <TemplateContext.Provider value={{ gridArea, setGridArea, isFullScreen, setFullScreen }}>
      <BlackOverlay />
      <AspectRatio>
        <div className={clsx(classes.container, `${gridTemplate[gridArea]}`)}>{children}</div>
      </AspectRatio>
    </TemplateContext.Provider>
  );
}
